import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ImageGrid } from "../components"

const About = () => {
  const { gridImages, image } = useImages()

  return (
    <Layout>
      <Seo title="Our Story" />

      <div id="hero" className="p-4 md:p-12">
        <div className="pt-4 max-w-2xl m-auto">
          <h1 className="md:pb-4 text-primary-500">Our Story</h1>
          <p className="pb-4 md:pb-8">
            It all started with music - Alicia first started crushin’ on Dillon
            when she saw him on stage at a show in high school junior year. They
            had a class together senior year and the rest is history. Just
            kidding - but after high school graduation Dillon approached Alicia
            at a show and that was the first time we officially met! We bonded
            over our love of our mutual favorite band (Bright Eyes). Hanging out
            at shows and similar crowds on and off for a few years would follow
            while we enjoyed our early twenties, and we ultimately began dating
            on November 20, 2013. “It was always you” is the quote that sums up
            our story. Countless shows, a beloved Bengal cat, a dream move to
            San Diego, growing careers, 2 Goldendoodles and all the highs & lows
            in between – we are adding marriage to our story! We cannot wait for
            you all to be a part of our celebration.
          </p>
        </div>

        <div className="mb-2">
          <Img fluid={image} />
        </div>

        <ImageGrid images={gridImages} />
      </div>
    </Layout>
  )
}

const useImages = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { eq: "us" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        image: file(relativePath: { eq: "old-timey.JPG" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return {
    image: data.image.childImageSharp.fluid,
    gridImages: data.allFile.edges,
  }
}

export default About
